.wrapper{ 
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 7rem;
  overflow-y: hidden;
  overflow-x: hidden;
}
.container{
  height: 100%;
  width: 100%;
  overflow: auto;
}
.heading{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.title{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.abt-intro{
  padding-top: 2rem;
  width: 75%;
}
.smiley{
  height: 11rem;
  width: 11rem;
  border-radius: 2%;
  border: 1px solid; 
  padding: 5px;
  margin: 2rem 2rem;
}
.typewriter h4 {
  overflow: hidden; 
  border-right: .15em solid orangered; 
  white-space: nowrap;
  margin: 0 auto; 
  letter-spacing: .15em;
  animation: 
    typing .7s steps(10),
    blink-caret .75s step-end infinite;
    position: absolute;
    width: 6.7rem;
}

@keyframes typing {
  from { width: 0 }
  to { width: 6.9rem }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ff4500; }
}
.chess-link{
  text-decoration: underline;
  color: #ff4500;
  font-size: 16px;
}
.chess-link:hover{
  text-decoration: none;
}
.bible-verse{
  color: orangered;
}
@media (max-width: 768px) {
  .wrapper {
    padding: 5%;
    padding-top: 7rem;
    overflow: auto;
    padding-bottom: 1rem;
  }

  .title {
    flex-direction: column-reverse; 
  }

  .abt-intro {
    padding: 8em 0 0 0;
    width: 100%; 
  }

  .smiley {
    width: 8rem;
    height: 8rem;
    margin: 0; 
  }

}