:root{
--dark: #111f32;
--light: #ececec;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Maitree', serif;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
#light{
  background: var(--light);
  color: var(--dark);
}
#dark{
  background: var(--dark);
  color: var(--light);
}
/*dark-light mode*/
.light-dark {
  position: absolute;
  bottom: 2.5rem;
  left: -.6rem;
  rotate: -90deg;
  display: inline-block;
  border-radius: 1rem;
  cursor: pointer;
  width: 60px;
  height: 30px;
  overflow: hidden;
  border: 1px solid orangered;
}

.checkbox {
  opacity: 0
}

/*rest of bg color 4 moon*/
.slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--light);
  transition: .4s;
}
 
/*moon phase*/
 .slider::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 40%;
  height: 80%;
  border-radius: 100%;
  transform: translateY(-50%);
  border: 4px solid var(--light);
  background-color: var(--dark);
  transition: .4s;
}

 .slider::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  border-radius: 100%;
  transform: translate(40%, -20%);
  background-color: var(--light);
  transition: .4s;
}

.checkbox:checked + .slider {
  background-color: var(--dark);
}

.checkbox:checked + .slider::before {
  border: 4px solid var(--dark);
  background-color: var(--light);
  transform: translate(90%, -50%);
}

.checkbox:checked + .slider::after {
  width: 0;
  height: 0;
  background-color: var(--dark);
}