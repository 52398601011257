
.editor {
  position: absolute;
  bottom: 0;
  width: 85%;
  height: 60%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  background-color: #82818119;
  border: 1px solid #8281813c;
  font-size: large;
}

.tabs {
  display: flex;
  background-color: #82818100;
  padding: 10px 0px 0px 10px;
}


.tab {
  width: 10em;
  height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
  border-radius: 5px 5px 0px 0px;
}

.tab:hover {
  color: orangered;
  background-color: #82818115;
}

.active {
  background-color: #82818115;
  font-weight: bold;
}

.lineNumbers {
  flex: 0 0 4px;
  background-color: #82818115;
  
  padding: 8px;
  width: 2rem;
  text-align: left;
  font-family: monospace, 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  
}
code span{
  padding: .2px 0px;
  display: flex;
}
.lineNumber {
  
  margin: 4px 5px;
}
.codes{
  display: flex;
}

.codeArea {
  flex: 1;
  padding: 10px;
  font-family: 'Consolas', 'Courier New', monospace;
  font-size: large;
  line-height: 1.6;
  white-space: pre-wrap;
  background-color: #82818115;
  overflow: auto;
  }
code{
  color: rgba(128, 128, 128, 0.778);
}
code span{

  display: flex;
  flex-wrap: wrap;
}
.blu-construct{
  color: #3473c0;
}
.wite-construct{
  color: white;
}
.wrapping-construct{
  color: #BA77FF;
}
.red-construct{
  color: #a03232;
}
.org-construct{
  color: #237929;
}
.prpl-construct{
  color: #FDF74A;
}

@media (max-width: 768px) {
  .editor {
    overflow-x: auto;
    overflow-y: auto;
  }
  .tab {
  }
  .lineNumbers {
  }
  .codeArea {
  }
}
