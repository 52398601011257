.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.card {
  display: none;
  text-align: center;
  border-radius: 2rem;
  transition: opacity 0.5s ease-in-out;
  width: 150px;
}

.card p{
  font-size: medium;
  width: 100%;
}
.card.active {
  display: block;
  opacity: 1;
}
