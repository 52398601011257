.waving-animation{
  position: absolute;
  bottom: 95px;
  padding-top: 15px;
}
.waving-animation .waving-arm {
  animation: waving 2s ease-in-out infinite;
}

.waving-body {
  position: absolute;
  right: -19px;
  width: 100px;
  height: 95px;
  border-radius: 30% 30% 0px 0px;
  background: #b9b9b9;
  transition: transform 0.3s ease;
}

.waving-face {
  position: absolute;
  width: 70px;
  height: 74px;
  right: 1%;
  bottom: 10%;
  transition: transform 0.3s ease;
}


.waving-arm {
  position: absolute;
  bottom: -95px;
  left: 0px;
  width: 24px;
  height: 140px;
  background: #b9b9b9;
  border-radius: 20px;
  overflow: visible;
  transform: translateY(100%);
  transition: transform 0.2s ease;
}

@keyframes waving {
  0%, 100% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(40deg);
  }
}

