
.container{
  height: 5rem;
  width: 100%;
  padding-top: 2.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
}
.navigation{
  display: flex;
  position: absolute;
  top: 1px;
  width: 100%;
  height: 100vh;
  font-size: xx-large;
  transform: translate(-100%,-100%);
  transition: 0.5s;
  z-index: 2;
} 

.navigation ol{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5rem;
  padding-left: 20%;
}
/* ALL A TAGS WORKING BUT NO TAGS ON NAV.JS */
a{
  font-size: 2.5rem;
  color: #111f32;
  text-decoration: none;
}
.dark a{
  color: #ececec;
}
a:hover {
  color: orangered;
  transition: all .4s;
}
.navigation li{
  font-size: large;
  padding-left: 1.5rem;
  list-style-type: decimal-leading-zero;
  transform: translate(10%,10000%);
  transition: 0.5s;
  color: orangered;
}
.activated{
  background-color: #e6e6e6;
  opacity: .95;
  display: flex;
  transform: translate(0%, 0%);
  transition: all .5s;
}
.dark{
  background-color: #111f32; 
}
.activated .dark{
  background-color: #111f32;
  opacity: .95;
}
.activated li{
  transform: translate(0%, -35%);
}

.logo{
  width: 15rem;
  z-index: 2;
}
.logo :hover{
  transform: scale(1.05);
  transition: all .7s;
}
.st0 {
  fill: #111f32;
}
.dark .st0{
  fill: #ececec;
}

/*hamburger*/
.hamburger {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  z-index: 3;
  transform: scale(1.2);
}

.hamburger input {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  position: absolute;
}

.hamburger div {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: absolute;
}

.hamburger span {
  background: var(--dark);
  width: 100%;
  height: 2px;
  border-radius: 5px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s, width 0.5s;
}
.hamburger .line-1 {
  width: 50%;
  transform: translate(-100%, -10px);
  transform-origin: 0 0;
}
.hamburger .line-dark{
  background-color: #ececec;
}
.hamburger .line-3 {
  width: 50%;
  left: auto;
  right: 0;
  transform: translate(0, 10px);
  transform-origin: 100% 0;
}
.hamburger:hover{
  color: #ff4500;
  border: .5px solid;
  transform: scale(1.4);
  transition: all .7s;
}
.activated + div span.line-1 {
  transform: translate(0, 0) rotate(-45deg);
}
.activated +  div span.line-3 {
  transform: translate(-100%, 0) rotate(-45deg);
}
.activated + div span.line-2 {
  transform: translate(-50%, -50%) rotate(45deg);
}
.media{
  width: 4rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  bottom: 1.5rem;
  right: 0rem;
  transform: translate(10000%,0%);
  transition: 0.7s;
}
.activated .media{
  transform: translate(0%, -55%);
}
.media a{
  height: 3.1rem;
  color: #ff4500;
}
.media a:hover{
  border-right: .5rem solid #ff4500;
}
.footer{
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  font-size: x-small;
  z-index: 1;
}
