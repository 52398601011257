.wrapper {
  height: 100vh;
  width: 100%;
  padding: 5%;
  padding-top: 7rem; 
  overflow-y: hidden;
  overflow-x: hidden;
}
.container {
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} 
.typewriter h4 {
  overflow: hidden;
  border-right: .15em solid orangered;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: 
    typing .7s steps(10),
    blink-caret .75s step-end infinite;
  position: absolute;
  width: 6.5rem;
}
@keyframes typing {
  from { width: 0 }
  to { width: 6.9rem }
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orangered; }
}
.banner {
  height: 17rem;
  margin-top: 5%;
}
.enteties {
  height: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid orangered;
}
.enteties .section {
  width: 100%;
  border: 1px solid orangered;
  display: grid;
}
.code-space {
  width: 100%;
  display: flex;
  justify-content: center;
}
.code-space p{
  padding: 0px 5rem;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 300;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 2%;
    padding-top: 7rem;
  }
  .container {
   justify-content: space-between;
   padding-bottom: 5%;
  }
  .banner {
    height: 10rem;
    margin-top: 3%;
  }
  .enteties {
    height: 40vh;
    flex-direction: column; 
  }
  .enteties .section {
    height: 100%;
    width: 100%;
  }
  .code-space {
    justify-content: center; 
  }
  .code-space p{
    padding: 0px 1rem;
    width: 100%;
  }
}

