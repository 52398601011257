.wrapper{
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column; 
  padding: 5%;
  padding-top: 7rem;
  overflow-y: hidden;
  overflow-x: hidden;
}
.container{
  height: 100%;
}
.typewriter h4 {
  overflow: hidden;
  border-right: .15em solid orangered;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: 
    typing .7s steps(13),
    blink-caret .75s step-end infinite;
    position: absolute;
    width: 7.5rem;
}
@keyframes typing {
  from { width: 0 }
  to { width: 7.9rem }
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orangered; }
}
.section{
  height: 100%;
  width: 100%;
  
  margin-top: 7em;
}
.inner-section{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 4rem;
}
.success-message{
  align-self: center;
  margin-top: 1.5rem;
  background-color: orangered;
  padding: 3px 7px;
  border-radius: 1em;
}
.fr-ptag{
  padding: 0px 5rem;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 300;
}
.s-media{
  color: orangered;
  text-decoration: underline;
  cursor: pointer;
}
/*form*/

.group {
  position: relative;
 }
 
 .input {
  color: #7d7d7d;
  font-size: 16px;
  padding: 10px 10px 2px 5px;
  display: block;
  width: 18rem;
  border: none;
  border-bottom: 1px solid #7d7d7d;
  background: transparent;
 }
 
 .input:focus {
  outline: none;
  border-bottom: orangered;
 }

 .message-input{
  font-family: sans-serif;
  color: #7d7d7d;
  font-size: 16px;
  padding: 15px 10px 2px 5px;
  display: block;
  width: 18rem;
  min-height: 1rem;
  max-height: 6.5rem;
  resize: none;
  overflow-x: hidden;
  border: none;
  border-left: 1px solid #7d7d7d;
  border-right: 1px solid #7d7d7d;
  border-bottom: 1px solid #7d7d7d;
  background: transparent;
 }

 .message-input:focus{
  outline: none;
  border-bottom: orangered;
  max-height: 6.5rem;
  overflow-x: hidden;
 }
 .form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3em;
  gap: 3em;
  align-items: center;
 }
 
 .form-label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 1px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
 }
 
 
 .input:focus ~ .label, .input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: orangered;
 }
 
 .message-input:focus ~ .label, .message-input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: orangered;
 }

 .bar {
  position: relative;
  display: block;
  width: 18rem;
 }
 
 .bar:before, .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: orangered;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
 }
 
 .bar:before {
  left: 50%;
 }
 
 .bar:after {
  right: 50%;
 }
 
 .input:focus ~ .bar:before, .input:focus ~ .bar:after {
  width: 50%;
 }

 .message-input:focus ~ .bar:before, .message-input:focus ~ .bar:after {
  width: 50%;
 }
 
 .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
 }
 
 .input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
 }

 .message-input:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
 }
 
 @keyframes inputHighlighter {
  from {
   background: orangered;
  }
 
  to {
   width: 0;
   background: transparent;
  }
 }

.error-message {
  color: rgb(189, 30, 30);
  font-size: 12px;
  margin-top: 4px;
}


.form-send-btn {
  background-color: #8281813c;
  border: none;
  width: 100px;
  height: 30px;
  border-radius: 10px;
  font-weight: 600;
  overflow: hidden;
  transition-duration: 700ms;
  margin-right: 10rem;
  color: orangered;
}

.form-send-btn:hover{
  color: white;
  background-color: #505050c2;
}

.form-send-btn.sent {
  background-color: rgba(255, 68, 0, 0.86);
  color: white;
  width: 120px;
  height: 40px;
  transition-delay: 100ms;
  transition-duration: 500ms;
}

.social-medias{
  width: 0%;
  height: 80%;
  align-items: center;
  display: flex;
}

@keyframes explode {
  0% {
    transform: scale(.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}


@media (max-width: 1024px) {
  .wrapper {
    padding: 10px; 
    padding-top: 7rem; 
  }

  .container {
    height: auto; 
  }

  .typewriter h4 {
    width: auto; 
  }

  .section {
    margin-top: 3em;
  }

  .inner-section {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
  }

  .fr-ptag {
    padding: 1rem 1rem 0rem 1rem;
  }

  .form {
    margin-top: 1.7em;
    width: 100%; 
    max-width: 100%; 
  }

  .input,
  .message-input {
    width: 100%;
    max-width: 100%; 
  }
  
  .social-medias {
    justify-content: end; 
    text-align: center;
    width: 90%;
    height: 30%; 
  }
 
}
